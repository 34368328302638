import React, { Fragment } from 'react'
import Title from '../CommonComponents/Title'
import { Box, Chip, Divider, Paper, Stack, Tooltip } from '@mui/material'
import { mapStatusToLabel, DOCUMENT_STATUSES_AND_DESCRIPTIONS } from '../common'
import StatusChip from '../CommonComponents/Chips/StatusChip'
import { CheckCircleOutlineOutlined } from '@mui/icons-material'
import { green, grey } from '@mui/material/colors'


const DemandWorkflowTooltip = ({ documentStatus }) => {
  const documentStatusIndex = DOCUMENT_STATUSES_AND_DESCRIPTIONS.findIndex(item => item.label === documentStatus)

  return (
    <Paper elevation={8} sx={{
      px: 2,
      py: 1,
    }}>
      <Title>
        Workflow
      </Title>
      <Stack alignItems={'flex-start'} sx={{ mt: .5 }}>
        {DOCUMENT_STATUSES_AND_DESCRIPTIONS.map((status, index) => {

          return <Fragment key={index}>
            {documentStatus === status.label ?
              <Tooltip title={status.description} placement='right' arrow>
                <Box>
                  <StatusChip
                    type='document'
                    documentStatus={status.label}
                    showInfoIcon={true}
                    showEditOffIcon={false}
                  />
                </Box>
              </Tooltip>
              :
              <Tooltip title={status.description} placement='right' arrow>
                <Box>
                  <Chip
                    label={mapStatusToLabel(status.label)}
                    variant='outlined'
                    icon={index <= documentStatusIndex ?
                      <CheckCircleOutlineOutlined color='success' />
                      : undefined
                    }
                    sx={{
                      background: index <= documentStatusIndex ? green[50] : grey[100],
                      borderColor: index <= documentStatusIndex ? green[800] : grey[400],
                    }}
                  />
                </Box>
              </Tooltip>
            }

            {index !== DOCUMENT_STATUSES_AND_DESCRIPTIONS.length - 1 &&
              <Divider orientation='vertical' sx={{ height: '16px', pl: 3 }} />
            }
          </Fragment>
        })}
      </Stack>
    </Paper >
  )
}

export default DemandWorkflowTooltip