import { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from '@mui/material';
import { useNavigate, useLocation } from 'react-router';
import { getUserApi } from '../api';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession, getCurrentUser, signIn, signOut } from '@aws-amplify/auth';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData, setUser } from '../redux/slices/userSlice';
import AuthenticationLoadingSpinner from './AuthenticationLoadingSpinner';
import '@aws-amplify/ui-react/styles.css';

const GlobalAuthenticator = ({ }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { route, authStatus, user, username, } = useAuthenticator();
  const { userData } = useSelector((state) => state.User);

  const isAuthenticated = authStatus === 'authenticated';
  const isAmplifyConfiguring = authStatus === 'configuring';
  // const isSetupRoute = route === 'setup';
  const [isLocalStateConfiguring, setIsLocalStateConfiguring] = useState(true);

  const getUserData = async () => {
    const authSession = await fetchAuthSession();
    const currentUser = await getCurrentUser()
    const accessToken = (authSession?.tokens?.accessToken || '').toString()
    const username = (currentUser?.username || '') || ((authSession?.userSub || '').toString()) || ''

    const authUser = {  //format expected payload for our pre-existing getUserApi call 
      username,
      signInUserSession: {
        accessToken: {
          jwtToken: accessToken
        }
      }
    }

    dispatch(setUser(authUser));
    try {
      const userDataWithAttributes = await getUserApi(authUser);
      dispatch(setUserData(userDataWithAttributes));
    } catch (error) {
      console.error('Error fetching user data', error);
    }
    setIsLocalStateConfiguring(false);
  }

  const hasUserData = Object.keys(userData ?? {}).length > 0;

  useEffect(() => {
    if (((!isAmplifyConfiguring && isLocalStateConfiguring)) || isAuthenticated) {
      if(isAuthenticated) {
        getUserData();
      } else {
        setIsLocalStateConfiguring(false);
      }
    }
  }
    , [isAuthenticated, isAmplifyConfiguring, isLocalStateConfiguring]);

  return ( <> {isLocalStateConfiguring && <AuthenticationLoadingSpinner />} </> );
}

export default GlobalAuthenticator;



