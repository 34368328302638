import { createSlice } from '@reduxjs/toolkit';

const initialMedicalsState = {
    medicalsLoading: false,
    medicals: {
        medicalTreatments: [],
    },
    // For displaying loading state on user updates to medicals.
    medicalsUpdateLoading: false
};

const medicalsSlice = createSlice({
    name: 'Medicals',
    initialState: initialMedicalsState,
    reducers: {
        setMedicalsTreatments: (state, action) => {
            state.medicals.medicalTreatments = action.payload;
        },
        setMedicalsLoading: (state, action) => {
            state.medicalsLoading = action.payload;
        },
        clearMedicalsState: () => initialMedicalsState,
        setMedicalsUpdateLoading: (state, action) => {
            state.medicalsUpdateLoading = action.payload
        },
    },
});

export const {
    setMedicalsTreatments,
    setMedicalsLoading,
    clearMedicalsState,
    setMedicalsUpdateLoading,
} = medicalsSlice.actions;

export default medicalsSlice.reducer;