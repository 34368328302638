import React, { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import { TextField, Autocomplete } from 'mui-rff';
import { Stack, DialogActions, Button, InputAdornment, DialogTitle, DialogContent, FormControl, FormHelperText } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { cleanNumber, getCurrentDate, formatDecimal } from '../common';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { INCLUDED_STATUS, MEDICAL_CHARGES_INSIGHTS_TYPE, MEDICAL_CHARGES_URL_PATH } from '../MedicalsComponents/insights';
import { setToast } from '../redux/slices/globalToastSlice';
import { useGetAllFilesByDocumentIdQuery } from '../services/documentFiles/documentFilesApi';
import { useAddEntityInsightDetailsMutation, useGetEntityInsightsQuery } from '../services/insights/insightsApi';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';
import { transformInsightMedicalsToFileMedicalsData } from "../MedicalsComponents/medicals";
import { isDemandOrCoverLetter } from "../common-document";
import { setMedicalsUpdateLoading } from "../redux/slices/medicalsSlice";

const AddMedicalTreatment = () => {
  const dispatch = useDispatch();

  const { addMedicalTreatment } = useSelector((state) => state.GlobalDialogues);
  const { data } = addMedicalTreatment;
  const { documentId } = data;

  const { data: documentFiles = [] } = useGetAllFilesByDocumentIdQuery(documentId);

  const getFileForPageNumber = (pageNumber) => {
      const file = documentFiles.find(
        ({ fileEntityData }) =>
          fileEntityData.startPage <= pageNumber && fileEntityData.endPage >= pageNumber
      ) || {};
      return file?.fileEntityData?.fileName || "";
  };

  const [ pageOptions, setPageOptions ] = useState([]);

  useEffect(() => {
      const filesWithoutCoverAndDemandLetter = documentFiles.filter(file => !isDemandOrCoverLetter(file.fileEntityData.detectedType));
      const startPage = filesWithoutCoverAndDemandLetter[0].fileEntityData.startPage;
      const lastPage = filesWithoutCoverAndDemandLetter[filesWithoutCoverAndDemandLetter.length - 1]?.fileEntityData?.endPage;

      setPageOptions(Array.from({ length: (lastPage - startPage) + 1 }, (_, index) => startPage + index));
  }, [documentFiles]);

  const [createMedicalTreatment] = useAddEntityInsightDetailsMutation();

  const { data: medicalCharges = [], isLoading: medicalsLoading } = useGetEntityInsightsQuery(
        {
          documentId,
          insightsType: MEDICAL_CHARGES_URL_PATH,
        },
        { refetchOnMountOrArgChange: true }
  );
  const medicals = transformInsightMedicalsToFileMedicalsData(medicalCharges);

  const validate = (values) => {
    const errors = {};
    !values.treatmentFacility && (errors.treatmentFacility = 'Required');
    !values.treatmentDate && (errors.treatmentDate = 'Required');
    !values.billedAmount && (errors.billedAmount = 'Required');
    !values.generatedPageNumber && (errors.generatedPageNumber = 'Required');

    const lastPage = documentFiles[documentFiles.length - 1]?.fileEntityData?.endPage;
    values.generatedPageNumber > lastPage && (errors.generatedPageNumber = 'Enter a valid page number');

    return errors;
  };

  const onSubmit = (values) => {
    dispatch(setMedicalsUpdateLoading({documentId, loading: true}));

    const generatedPageNumber = values.generatedPageNumber ? parseInt(values.generatedPageNumber) : '';

    const file =
      documentFiles.find(
        ({ fileEntityData }) =>
          fileEntityData.startPage <= generatedPageNumber && fileEntityData.endPage >= generatedPageNumber
      ) || {};

    // Calculate file page number
    const page_number = generatedPageNumber - file?.fileEntityData?.startPage + 1 || 1;

    const newMedicalTreatment = {
      entityInsightId: values.medicalTreatmentId,
      entityInsightType: MEDICAL_CHARGES_INSIGHTS_TYPE,
      fileEntityId: file?.fileEntityId,
      entityInsightData: {
        treatments: values.treatments,
        boundingBox: [],
        page_number,
        billedAmount: cleanNumber(values.billedAmount),
        treatmentDate: values.treatmentDate,
        treatmentType: values.treatmentType,
        treatmentFacility: values.treatmentFacility.trim(),
        status: INCLUDED_STATUS
      },
    };

    createMedicalTreatment({
      documentId,
      newEntityInsights: [newMedicalTreatment],
    })
      .unwrap()
      .catch((error) => {
        console.log('error', error);
        dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error with adding medical treatment' }));
      })
      .finally(() => {
        dispatch(setMedicalsUpdateLoading(false));
      });

    handleClose();
  };

  const handleClose = () => dispatch(resetGlobalDialogues());

  return (
    <>
      <DialogTitle>Add medical billing charge</DialogTitle>
      <Form
        onSubmit={onSubmit}
        initialValues={{ medicalTreatmentId: uuidv4() }}
        validate={validate}
        render={({ handleSubmit, form, valid, values}) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Stack spacing={2}>
                  <Autocomplete
                      options={Array.from(
                        new Set(
                          medicals.medicalTreatments
                            .map((row) => row["treatmentFacility"])
                            .filter((value) => value != null && value !== '')
                        )
                      )}
                      name="treatmentFacility"
                      freeSolo
                      fullWidth
                      multiline
                      getOptionLabel={(option) =>
                        typeof option === 'string' || option instanceof String ? option : ''
                      }
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          <TextField
                            {...params}
                            label="Facility"
                            name="treatmentFacility"
                            fullWidth
                            required={true}
                            InputProps={{
                              ...params.InputProps
                            }}
                          />
                        </div>
                      )}
                  />
                  <Autocomplete
                      options={Array.from(
                        new Set(
                          medicals.medicalTreatments
                            .map((row) => row["treatmentType"])
                            .filter((value) => value != null && value !== '')
                        )
                      )}
                      name="treatmentType"
                      freeSolo
                      fullWidth
                      multiline
                      getOptionLabel={(option) =>
                        typeof option === 'string' || option instanceof String ? option : ''
                      }
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          <TextField
                            {...params}
                            label="Type"
                            name="treatmentType"
                            fullWidth
                            required={false}
                            InputProps={{
                              ...params.InputProps
                            }}
                          />
                        </div>
                      )}
                  />

                  <TextField
                    label="Date of service"
                    name="treatmentDate"
                    type="date"
                    required
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ max: getCurrentDate() }}
                  />

                  <TextField
                    label="Charged amount"
                    name="billedAmount"
                    required
                    onChangeCapture={(e) => (e.target.value = formatDecimal(e.target.value))}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                  />

                  <TextField label="Treatments" name="treatments" />

                  <FormControl fullWidth>
                      <Autocomplete
                        options={pageOptions}
                        name="generatedPageNumber"
                        getOptionLabel={(option) => option.toString()}
                        renderInput={(params) => <TextField
                            {...params}
                            label="Page number"
                            name="generatedPageNumber"
                            required
                        />}
                      />
                      <FormHelperText>{getFileForPageNumber(values.generatedPageNumber)}</FormHelperText>
                  </FormControl>
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button color="secondary" variant="text" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="secondary" disabled={!valid}>
                  Save
                </Button>
              </DialogActions>
            </form>
          );
        }}
      />
    </>
  );
};

export default AddMedicalTreatment;
