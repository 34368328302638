import { useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useRef, useState, createRef } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, getGridStringOperators, getGridSingleSelectOperators } from '@mui/x-data-grid-pro';
import { LinearProgress, Stack, IconButton, Button, Tooltip, ToggleButton, ToggleButtonGroup, Grid, Container, Toolbar, Box, Typography, Chip } from '@mui/material';
import Title from './CommonComponents/Title';
import { dateComparator } from './comparators';
import { documentStatusDropDownValues, deliveryStatusDropDownValues, convertUTCDateTimeToLocalTime, convertUTCDateTimeToLocalDate, mapDeliveryStatusToLabel, mapDeliveryStatusToChipClass, mapStatusToChipClass, mapStatusToLabel, userHasPermission, DEFAULT_PAGE_SIZE } from './common';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined, OpenInNewOutlined, RateReviewOutlined, RefreshOutlined } from '@mui/icons-material';
import { ContactLogAddDialog } from './ContactLogAddDialog';
import QuickFilters from './QuickFilters/QuickFiltersComponent';
import ContactLogDetailPanelContent from './ContactLogDetailPanelContent';
import { pageChange, pageSizeChange, sortChange, setLoading, setDemandData, updateFilterState } from './redux/slices/contactLogInventorySlice';
import { isEqual } from 'lodash'
import { fetchInventoryDemandData } from './redux/thunks/demandData';
import { useGetDocumentDataQuery } from './services/documentData/documentDataApi';
import { fetchDocumentData } from './redux/thunks/documentData';

const ContactLogInventoryView = props => {
    const [currentDocumentId, setCurrentDocumentId] = useState();
    const quickFilterName = "ContactLogInventory";
    const filterState = useSelector(state => state[quickFilterName]).filterState;
    const { filterModel } = filterState;
    const { 
        pagination: paginationState,
        filterState: contactLogFilterState,
        contactLogResponseData,
        loading
     } = useSelector((state) => state.ContactLogInventory);

    const { data: documentData = {} } = useGetDocumentDataQuery({ documentId: currentDocumentId, pollPdfStatus: false }, { skip: !currentDocumentId });

    const Doc = useSelector((state) => state.Document);
    const { userData, user  } = useSelector((state) => state.User);


    const stringOperators = getGridStringOperators().filter(
        (operator) => operator.value !== 'isAnyOf'
    )
    const selectOperators = getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is'
    );
    const dateOperators = getGridStringOperators().filter(
        (operator) => operator.value === 'contains'
    );

    const apiRef = useRef({});
    const dispatch = useDispatch();
    const gridRef = createRef();
    const location = useLocation();
    const navigate = useNavigate();
    const [filterToggleValue, setFilterToggleValue] = useState("active");
    const isActive = filterToggleValue === "active";
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [currentDocumentRow, setCurrentDocumentRow] = useState();
    const [muiTableKey, setMuiTableKey] = useState(0);
    const [tableFiltersActive, setTableFiltersActive] = useState(false);
    const [visibleRows, setVisibleRows] = useState({});

    const demandData = isActive ? contactLogResponseData.activeDocuments : contactLogResponseData.inActiveDocuments;
    const token = user?.signInUserSession?.accessToken?.jwtToken;
    const [inactiveInventoryDataFetched, setInactiveInventoryDataFetched] = useState(false);
    const activeQuickFilter = filterState.quickFilters.find((element) => element.toggleState).id;
    const totalRows = (isActive ? contactLogResponseData.activeTotalRows : contactLogResponseData.inActiveTotalRows) || 0;
    const totalDocuments = (isActive ? contactLogResponseData.activeTotalDocuments : contactLogResponseData.inActiveTotalDocuments) || 0;
    const statusCounts = isActive ? contactLogResponseData.activeStatusCounts : contactLogResponseData.inActiveStatusCounts;

    const requestBody = {  //requestBody is conditional on the filterToggleValue
        "active_filter": isActive,
        "page_size": DEFAULT_PAGE_SIZE
    };

    const handleInventoryRefresh = () => {
        dispatch(setLoading(true));
        dispatch(fetchInventoryDemandData({ requestBody, token }));
    };

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: paginationState.pageSize,
    });

    const [sortModel, setSortModel] = useState([{
        field: paginationState.sort,
        sort: paginationState.sortDirection
    }]);

    const getVisibleRows = () => apiRef?.current?.state?.visibleRowsLookup;
    useEffect(() => {
        const visRows = getVisibleRows();
        if (!isEqual(visRows, visibleRows)) {
            setVisibleRows(visRows);
        }
    }, [getVisibleRows()]);

    const handleFilterModelChange = (params) => {
        setTableFiltersActive(true);
        setVisibleRows(getVisibleRows());

        dispatch(updateFilterState({ ...filterState, filterModel: params }));

    }

    const handleQuickFilterClick = () => {
        setTableFiltersActive(false);
        setMuiTableKey(muiTableKey + 1);
    }

    useEffect(() => {
        if (!inactiveInventoryDataFetched && !isActive) {
            handleInventoryRefresh();
            setInactiveInventoryDataFetched(true);
        }
    }, [isActive]);

    useEffect(() => {
        paginationModel.page = paginationState.page;
        paginationModel.pageSize = paginationState.pageSize;
        getDemandListApi(true, paginationModel.page);
    }, []);

    useEffect(() => {
        getDemandListApi(paginationModel.page);
    }, [filterModel]);

    useEffect(() => {
        if (paginationModel.pageSize !== paginationState.pageSize) {
            dispatch(pageSizeChange({ pageSize: paginationModel.pageSize }));
            getDemandListApi(isActive, paginationModel.page);
        }
        else if (paginationModel.page !== paginationState.page) {
            dispatch(pageChange({ page: paginationModel.page }))
            getDemandListApi(isActive, paginationModel.page);
        }
    }, [paginationModel]);

    useEffect(() => {
        if (activeQuickFilter !== filterState.activeQuickFilter) {
            console.log("Quick filter changed to: " + activeQuickFilter);
            dispatch(updateFilterState({ ...filterState, activeQuickFilter: activeQuickFilter }));
            getDemandListApi(isActive, paginationModel.page);
        }
    }, [filterState]);

    useEffect(() => {
        if (sortModel.length > 0) {
            if (sortModel[0].field !== paginationState.sort || sortModel[0].sort !== paginationState.sortDirection) {
                dispatch(sortChange({ sort: sortModel[0].field, sortDirection: sortModel[0].sort }));
                getDemandListApi(isActive, paginationModel.page, sortModel[0].field, sortModel[0].sort);
            }
        }
    }, [sortModel]);


    const getDetailPanelContent = React.useCallback(
        ({ row }) => {
            return (<ContactLogDetailPanelContent row={row} showHeader={true} />);
        },
        [],
    );

    const getDemandListApi = async (active_filter, page, sortColumn = null, sortDirection = null) => {
        if (page === undefined) {
            page = 0;
        }
        requestBody["page"] = page;
        requestBody["page_size"] = paginationModel.pageSize;
        requestBody["sort_column"] = sortColumn || paginationState.sort;
        requestBody["sort_direction"] = sortDirection || paginationState.sortDirection;
        requestBody["quick_filter"] = activeQuickFilter;
        requestBody["column_filter_model"] = contactLogFilterState.filterModel;
        dispatch(fetchInventoryDemandData({ requestBody, token: user.signInUserSession.accessToken.jwtToken }));
        dispatch(setLoading(true));
    };

    const handleFilterToggleChange = (event, newFilterToggleValue) => {
        if (newFilterToggleValue !== null) {
            paginationModel.page = 0;
            setPaginationModel({ ...paginationModel });
            dispatch(pageChange({ page: 0, hasNextPage: false }))
            setFilterToggleValue(newFilterToggleValue);
        }
    };

    const handleContactLogAddCallback = (contactLog, document, metadata, setSaving) => {
        for (let i = 0; i < demandData.length; i++) {
            if (demandData[i].documentId == document.documentId) {
                getDemandListApi(isActive, paginationModel.page);
                break;
            }
        }

        setAddDialogOpen(false);
        setSaving(false);
    };

    const handleAddContactLogClick = (row, event) => {
        dispatch(fetchDocumentData({ documentId: row.documentId, user }))
            .then((response) => {
                setCurrentDocumentId(row.documentId);
                setCurrentDocumentRow(row);
                setAddDialogOpen(true);
            })
    };

    const handleAddDialogClose = () => {
        setAddDialogOpen(false);
    };

    const handleNavToDemand = (documentId) => {
        navigate('/a/' + documentId, { state: { from: location.pathname } });
    };

    window.addEventListener('resize', function (event) {
        resizeGrid();
    }, true);

    useEffect(() => {
        resizeGrid();
    }, [gridRef]);

    // Grid Columns
    const demand_col = {
        field: 'demand_id',
        headerName: 'Demand',
        width: 150,
        filterOperators: stringOperators,
        valueGetter: (params) => {
            return params.row.claimNumber || params.row.documentId
        },
        renderCell: (params) => {
            return (
                <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                    <a href={"/a/" + params.row.documentId} onClick={(e) => { handleNavToDemand(params.row.documentId); }}>
                        <Typography variant="tableP1" color="secondary">
                            {params.row.claimNumber || params.row.documentId}
                        </Typography>
                    </a>
                </div>
            )
        }
    };

    const client_col = {
        field: 'client_name',
        headerName: 'Client',
        width: 150,
        filterOperators: stringOperators,
        valueGetter: (params) => {
            if (params.row.claimantFirstName !== null || params.row.claimantLastName !== null) {
                return (params.row.claimantFirstName ?? "") + " " + (params.row.claimantLastName ?? "")
            }
            return "Not on File"
        },
        renderCell: (params) => {
            return (
                <Typography variant="tableP1">
                    {params.row.claimantFirstName ?? ""} {params.row.claimantLastName ?? ""}
                </Typography>
            )
        }
    };

    const adjuster_col = {
        field: 'adjuster_name',
        headerName: 'Adjuster',
        width: 150,
        filterOperators: stringOperators,
        valueGetter: (params) => {
            if (params.row.recipientAdjusterFirstName != null || params.row.recipientAdjusterLastName != null) {
                return (params.row.recipientAdjusterFirstName ?? "") + " " + (params.row.recipientAdjusterLastName ?? "")
            }
            return "Not on file"
        },
        renderCell: (params) => {
            var adjuster = "Not on file";
            var adjusterPhone = "";
            if (params.row.recipientAdjusterFirstName != null || params.row.recipientAdjusterLastName != null) {
                adjuster = (params.row.recipientAdjusterFirstName ?? "") + " " + (params.row.recipientAdjusterLastName ?? "");
                adjusterPhone = params.row.recipientAdjusterPhoneNumber;
            }

            return (
                <Stack>
                    <Typography variant="tableP1">
                        {adjuster}
                    </Typography>
                    <Typography variant="tableP2">
                        {adjusterPhone}
                    </Typography>
                </Stack>
            );
        }
    };

    const carrier_col = {
        field: 'carrier',
        headerName: 'Carrier',
        width: 200,
        headerAlign: 'left',
        align: 'left',
        filterOperators: stringOperators,
        valueGetter: (params) => {
            return params.row.carrierCommonName
        },
        renderCell: (params) => {
            return (
                <Stack>
                    <Typography variant="tableP1">
                        {params.row.carrierCommonName}
                    </Typography>
                    <Typography variant="tableP2">
                        {params.row.claimCoverage || "Not on file"}
                    </Typography>
                </Stack>
            );
        }
    };

    const firm_col = {
        field: 'lawfirm',
        headerName: 'Firm',
        width: 200,
        headerAlign: 'left',
        align: 'left',
        filterOperators: stringOperators,
        valueGetter: (params) => {
            return params.row.firmName
        },
        renderCell: (params) => {
            return (
                <Typography variant="tableP1">
                    {params.row.firmName}
                </Typography>
            );
        }
    };

    const date_created_col = {
        field: 'date_created',
        headerName: 'Created',
        width: 135,
        filterOperators: dateOperators,
        sortComparator: dateComparator,
        valueGetter: (params) => {
            return params.row.createdTs * 1000;
        },
        renderCell: (params) => {
            return (
                <Stack>
                    <Typography variant="tableP1">
                        {convertUTCDateTimeToLocalDate(new Date(params.row.createdTs * 1000))}
                    </Typography>
                    <Typography variant="tableP2">
                        {convertUTCDateTimeToLocalTime(new Date(params.row.createdTs * 1000))}
                    </Typography>
                </Stack>
            );
        }
    };

    const last_contact_col = {
        field: 'last_contact',
        headerName: 'Last contact',
        width: 135,
        filterOperators: dateOperators,
        sortComparator: dateComparator,
        valueGetter: (params) => {
            if (params.row.lastContactTs) {
                return params.row.lastContactTs;
            }
            else
                return null
        },
        renderCell: (params) => {
            if (params.row.lastContactTs) {
                return (
                    <Stack>
                        <Typography variant="tableP1">
                            {convertUTCDateTimeToLocalDate(new Date(params.row.lastContactTs * 1000))}
                        </Typography>
                        <Typography variant="tableP2">
                            {convertUTCDateTimeToLocalTime(new Date(params.row.lastContactTs * 1000))}
                        </Typography>
                    </Stack>
                );
            }
            else {
                return null
            }
        }
    };

    const date_submitted_col = {
        field: 'date_submitted',
        headerName: 'Submitted',
        width: 115,
        filterOperators: dateOperators,
        sortComparator: dateComparator,
        valueGetter: (params) => {
            const statusEvent = params.row.dateSubmitted;
            if (statusEvent) {
                return statusEvent * 1000;
            }
            else {
                return 0;
            }
        },
        renderCell: (params) => {
            const statusEvent = params.row.dateSubmitted;
            if (statusEvent) {
                return (
                    <Stack>
                        <Typography variant="tableP1">
                            {convertUTCDateTimeToLocalDate(new Date(statusEvent * 1000))}
                        </Typography>
                        <Typography variant="tableP2">
                            {convertUTCDateTimeToLocalTime(new Date(statusEvent * 1000))}
                        </Typography>
                    </Stack>
                );
            }
            else {
                return "";
            }
        }
    };

    const document_status_col = {
        field: 'status',
        headerName: 'Document status',
        width: 250,
        headerAlign: 'left',
        align: 'left',
        filterOperators: selectOperators,
        type: "singleSelect",
        valueOptions: documentStatusDropDownValues,
        valueGetter: (params) => {
            return mapStatusToLabel(params.row.documentStatus);
        },
        renderCell: (params) => (
            <Chip
                className={mapStatusToChipClass(params.row.documentStatus)}
                label={mapStatusToLabel(params.row.documentStatus)}
            />
        )
    };

    const delivery_status_col = {
        field: 'delivery_status',
        headerName: 'Delivery status',
        width: 200,
        filterOperators: selectOperators,
        type: "singleSelect",
        valueOptions: deliveryStatusDropDownValues,
        valueGetter: (params) => {
            return mapDeliveryStatusToLabel(params.row.deliveryStatus);
        },
        renderCell: (params) => (
            <Chip
                className={mapDeliveryStatusToChipClass(params.row.deliveryStatus)}
                label={mapDeliveryStatusToLabel(params.row.deliveryStatus)}
            />
        )
    };

    const action_col = {
        field: 'action',
        headerName: '',
        width: 60,
        resizable: false,
        filterable: false,
        editable: false,
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        renderCell: (params) => {
            if (userHasPermission("ContactLog", userData)) {
                return (
                    <div>
                        <Tooltip placement="right" title="Add to contact log" arrow>
                            <IconButton onClick={(e) => { handleAddContactLogClick(params.row, e) }}><RateReviewOutlined></RateReviewOutlined></IconButton>
                        </Tooltip>
                    </div>)
            }
            else
                return null;
        }
    };

    const resizeGrid = () => {
        if (gridRef.current != null) {
            let windowH = window.innerHeight - 200;
            gridRef.current.style.height = windowH + 'px';
        }
    };

    const columns = [demand_col, action_col, carrier_col, adjuster_col, firm_col, client_col, date_created_col, last_contact_col,
        date_submitted_col, document_status_col, delivery_status_col,];

    return (
        <div >
            <Container maxWidth="">
                <Stack direction="column" spacing={2}>
                    <Toolbar />
                    <Box>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Title>Demands</Title>
                            <Stack direction={"row"} spacing={2}>
                                <Button variant="text" color='secondary'
                                    onClick={(event) => { window.open("https://precedentnc.sharepoint.com/:w:/s/ProjectShortCircuit/Ec971Jkj4GlGpeyZl1KbUpQBKsZ1xC9ZoGfvXiLnh-zefw?e=sbxE4h") }}
                                    startIcon={<OpenInNewOutlined />}>Instructions</Button>
                                <Button variant="text" color='secondary'
                                    onClick={(event) => { window.open("https://precedentnc.sharepoint.com/:x:/s/ProjectShortCircuit/ETuNKexfn5dPpw7BIBQJPJ8BmNMEWQJJD-zMGr-rJbT3dA?e=EO8CcF") }}
                                    startIcon={<OpenInNewOutlined />}>Carrier contact info</Button>
                            </Stack>
                        </Stack>

                        <Stack direction={"row"} justifyContent={"space-between"} spacing={2} sx={{ mt: 1.5 }}>

                            <Stack direction={"row"} justifyContent={"flex-start"}>
                                <Grid container sx={{ display: 'flex' }}>
                                    {filterToggleValue === 'active' && <QuickFilters handleClick={handleQuickFilterClick} filterName={quickFilterName} loading={loading} data={demandData} statusCounts={statusCounts} rowsInGrid={totalRows} totalDocuments={totalDocuments} isActive={isActive} visibleRows={visibleRows} tableFiltersActive={tableFiltersActive} />}
                                </Grid>
                            </Stack>

                            <Stack direction={"row"} spacing={2} sx={{ height: 38.75 }} justifyContent={"flex-end"}>
                                <Stack direction={"row"} spacing={2} width={"65%"} justifyContent={"flex-end"}>
                                    <Tooltip placement="left" title={`Refresh ${isActive ? 'active' : 'inactive'} contact logs`} arrow>
                                        <IconButton title='Referesh contact log table' onClick={handleInventoryRefresh}><RefreshOutlined></RefreshOutlined></IconButton>
                                    </Tooltip>
                                </Stack>

                                <ToggleButtonGroup
                                    value={filterToggleValue}
                                    exclusive
                                    onChange={handleFilterToggleChange}
                                >
                                    <ToggleButton value="active">Active</ToggleButton>
                                    {userHasPermission('ArchivedDemandPreview', userData) && (
                                        <ToggleButton value="inactive">Inactive</ToggleButton>
                                    )}
                                </ToggleButtonGroup>

                            </Stack>
                        </Stack>

                        <DataGridPro pagination ref={gridRef} getRowId={(row) => row.documentId} columns={columns} rows={demandData}
                            apiRef={apiRef}
                            key={muiTableKey}
                            rowCount={totalRows}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: paginationState.sort, sort: paginationState.sortDirection }],
                                },
                                pinnedColumns: { left: [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field, 'demand_id', 'action'] },
                                pagination: { paginationModel: { pageSize: paginationState.pageSize } }
                            }}
                            getRowHeight={() => 'auto'}
                            slots={{
                                loadingOverlay: LinearProgress,
                                detailPanelExpandIcon: KeyboardArrowDownOutlined,
                                detailPanelCollapseIcon: KeyboardArrowUpOutlined
                            }}
                            disableRowSelectionOnClick={true}
                            disableColumnSelector={true}
                            loading={loading}
                            pageSizeOptions={[25, 50, 100]}
                            filterModel={filterModel}
                            filterMode='server'
                            filterDebounceMs={500}
                            paginationModel={paginationModel}
                            paginationMode='server'
                            onPaginationModelChange={setPaginationModel}
                            sortingMode='server'
                            onSortModelChange={setSortModel}
                            onFilterModelChange={handleFilterModelChange}
                            getDetailPanelContent={getDetailPanelContent}
                            sx={{
                                mt: 1
                            }}

                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'doc-row-even' : 'doc-row-odd'
                            }
                        >
                        </DataGridPro>

                        <ContactLogAddDialog
                            callback={handleContactLogAddCallback}
                            handleClose={handleAddDialogClose}
                            dialogOpen={addDialogOpen}
                            user={user}
                            documentId={currentDocumentId}
                            documentRow={currentDocumentRow}>
                            currentDocumentData={documentData}
                        </ContactLogAddDialog>

                    </Box>
                </Stack>
            </Container>

        </div>
    );

}

export default ContactLogInventoryView;