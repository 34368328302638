import React, { useState, useEffect } from "react";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { signOut } from '@aws-amplify/auth';
import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Link } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { AdminPanelSettings } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import WbCloudyTwoToneIcon from '@mui/icons-material/WbCloudyTwoTone';
import LogoutIcon from '@mui/icons-material/Logout';
import awsExports from './aws-exports';
import { Avatar, Button, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { isLawFirmUser, isPrecedentUser, PRECEDENT_ADMIN_ROLE } from './common-roles';
import { userHasRole } from './common';
import { theme } from './Theme'
import { persistor } from './App';
import { setUser as setUserRedux, setUserData as setUserDataRedux, setIsAuthenticated as setIsAuthenticatedRedux, resetUserState, logout } from './redux/slices/userSlice';
import { useSelector, useDispatch } from "react-redux";
const imageUrl = "/precedent-logo-white.png";
const homeUrl = "/";
const showStage = (awsExports.ENV_STAGE.toUpperCase() !== "PROD") && awsExports.ENV_STAGE
const styles = { color: '#ffffff', display: 'block', textTransform: 'none', '&:hover': { backgroundColor: '#ffffff25' } };

const HeaderAppBar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { user, userData } = useSelector((state) => state.User);
  const { isDisplayed } = useSelector((state) => state.Navbar);  //only used to toggle display of the appbar for custom rendering requirements on demand
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [pages, setPages] = useState([]);
  const { authStatus, route } = useAuthenticator();  //this is from '@aws-amplify/ui-react';
  const isAuthenticated = authStatus === 'authenticated';
  const handleOpenNavMenu = (event) => { setAnchorElNav(event.currentTarget); };
  const handleOpenUserMenu = (event) => { setAnchorElUser(event.currentTarget); };
  const handleCloseNavMenu = () => { setAnchorElNav(null); };
  const handleCloseUserMenu = () => { setAnchorElUser(null); };

  const handleSignout = async () => {
    handleCloseUserMenu();
    dispatch(resetUserState());  //resets user state which is pre-emptively redundant b/c we reset all redux state below. but that's okay.
    dispatch(logout());  //reset all redux state
    signOut();  //sign out of cognito
  };

  const hasRoles = userData.roles && userData.roles.length > 0;  //make sure userData is populated with roles before rendering and mapping page links

   useEffect(() => {  //page link mapping based on user roles
    if (hasRoles) {
      const localPages = [];
      if (isLawFirmUser(userData)) {
        localPages.push({ label: 'Inventory', route: '/inventory' });
      }
      else if (isPrecedentUser(userData)) {
        localPages.push({ label: 'Inventory', route: '/inventory' });
        localPages.push({ label: 'Contact log', route: '/contact-log-inventory' });
        if (userHasRole(PRECEDENT_ADMIN_ROLE, userData)) {
          localPages.push({ label: 'Admin', route: '/customer-management' });
        }
      }
      setPages(localPages);
    } else {
      setPages([]);
    }
  }, [hasRoles]);

  return (
    <>
      {isDisplayed ? (
        <ThemeProvider theme={theme}>
          <AppBar sx={{ position: 'fixed', zIndex: 12, height: "64px" }}>
            <Container maxWidth="">
              <Toolbar disableGutters sx={{ height: "64px" }}>
                {/* logo and stage for large view */}
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <Stack direction="row" spacing={2} display="flex" alignItems="center">
                    <Link to={homeUrl}>
                      <Box display="flex" alignItems={"center"}>
                        <img src={imageUrl} height="35" alt='Precedent Exchange' />
                      </Box>
                    </Link>
                    {showStage ? (
                      <Stack direction="row" sx={{ border: "2px solid #ED6C02", borderRadius: "4px" }} spacing={1} padding={.5} display="flex" alignItems="center">
                        <WbCloudyTwoToneIcon color="warning" />
                        <Typography variant="h6" sx={{ color: "#ed6c02" }} className="env-stage">{awsExports.ENV_STAGE}</Typography>
                      </Stack>
                    ) : null}
                  </Stack>
                </Box>
                {/* hamburger menu for small view */}
                <Box sx={{ flexGrow: .33, display: { xs: 'flex', md: 'none' } }}>
                  {(isAuthenticated && hasRoles) ? (
                    <>
                      <IconButton size="large" aria-label="menu-for-site-nav" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit" >
                        <MenuIcon />
                      </IconButton>
                      <Menu id="menu-appbar" anchorEl={anchorElNav} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} keepMounted disableScrollLock transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(anchorElNav)} onClose={handleCloseNavMenu} sx={{ display: { xs: 'block', md: 'none' }, }} >

                        {pages.map((page) => (
                          <MenuItem key={page.route} onClick={handleCloseNavMenu}>
                            <Button key={page.route} to={page.route} component={Link} onClick={handleCloseNavMenu} sx={styles} variant="text">
                              <Typography textAlign="center" color="primary">{page.label}</Typography>
                            </Button>
                          </MenuItem>
                        ))}

                      </Menu>
                    </>) : null}
                </Box>

                {/* center aligned logo and stage for small view */}
                <Box justifyContent="center" sx={{ flexGrow: .33, display: { xs: 'flex', md: 'none' } }}>
                  <Stack direction="row" spacing={2} display="flex" alignItems="center" >
                    <Link to={homeUrl}>
                      <Box display="flex" alignItems={"center"}>
                        <img src={imageUrl} height="35" alt='Precedent Exchange' />
                      </Box>
                    </Link>
                    {showStage ? (
                      <Stack direction="row" sx={{ border: "2px solid #ED6C02", borderRadius: "4px" }} spacing={1} padding={.5} display="flex" alignItems="center">
                        <WbCloudyTwoToneIcon color="warning" />
                        <Typography variant="h6" style={{ color: "#ed6c02" }} > {awsExports.ENV_STAGE}</Typography>
                      </Stack>
                    ) : null}
                  </Stack>
                </Box>
                {/* nav links for large views */}
                <Stack direction="row" spacing={1} sx={{ ml: 4, flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  {pages.map((page) => (
                    <Box key={page.route} className={page.route === location.pathname ? 'App-header-tab App-header-tab-selected' : 'App-header-tab'} onClick={handleCloseNavMenu} display="flex" alignItems={"center"}>
                      <Button key={page.route} to={page.route} component={Link} onClick={handleCloseNavMenu} sx={styles} variant="text">
                        <Typography variant="body1">{page.label}</Typography>
                      </Button>
                    </Box>
                  ))}
                </Stack>
                {/* avatar for signout */}
                <Box sx={{ flexGrow: .33, display: "flex", justifyContent: "flex-end" }}>
                  {isAuthenticated && hasRoles ? (
                    <Stack direction="row" spacing={2} display="flex" alignItems={"center"}>
                      {showStage ? (
                        <Stack direction="row" spacing={1}>
                          <AdminPanelSettings />
                          <Typography variant="body1">
                            {userData.roles[0]}
                          </Typography>
                        </Stack>) : null}
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar >{userData.firstName[0]}{userData.lastName[0]}</Avatar>
                      </IconButton>
                    </Stack>) : null}

                  <Menu sx={{ mt: '45px' }} id="menu-appbar" anchorEl={anchorElUser} anchorOrigin={{ vertical: 'top', horizontal: 'right', }} keepMounted disableScrollLock transformOrigin={{ vertical: 'top', horizontal: 'right', }} open={Boolean(anchorElUser)} onClose={handleCloseUserMenu} >

                    <MenuItem key="signOut" onClick={handleSignout}>
                      <ListItemIcon>
                        <LogoutIcon />
                      </ListItemIcon>
                      <Typography textAlign="center">Sign out</Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
        </ThemeProvider>
      ) : null}
    </>
  );
}

export default HeaderAppBar;