import { useEffect } from "react";
import { useNavigate, useLocation, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Authenticator, useAuthenticator, View, Heading, useTheme, ThemeProvider } from '@aws-amplify/ui-react';
import { Box, Container, Stack, Toolbar, Typography } from '@mui/material';
import AuthInstructions from './AuthInstructions'
import Countdown from "./Countdown";
import awsExports from '../aws-exports';
import { userHasRole } from '../common';
import '@aws-amplify/ui-react/styles.css';

const NewLoginComponent = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { route, authStatus } = useAuthenticator();
  const isConfiguring =authStatus === 'configuring';
  const { user, userData } = useSelector((state) => state.User);
  const isAuthenticated = authStatus === 'authenticated';
  const hasUserData = Object.keys(userData ?? {}).length > 0;
  let from = location.state?.from?.pathname  

  useEffect(() => {
    document.addEventListener('keyup', (e) => { trimFields() })
    return () => {
      document.removeEventListener('keyup', (e) => { trimFields() })
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated && hasUserData) {
      const defaultRoute = from ? from : userHasRole('PrecedentCaller', userData) ? '/contact-log-inventory' : '/inventory';
      navigate(defaultRoute);
    }
  }, [isAuthenticated, hasUserData]);

  const userNameField = document.querySelector('input[name="username"]');
  const passwordField = document.querySelector('input[type="password"]');
  const isSetupTOTPRoute = route === 'setupTotp';
  const isForceNewPasswordRoute = route === 'forceNewPassword';
  const trimFields = () => [userNameField, passwordField].forEach(field => field?.value && (field.value = field.value.replace(/ /g, '').trim()));
  const getPasswordRulesList = () => { return (`<ul style={{marginBottom: 0}}> <li>At least 8 characters</li> <li>1 number</li> <li>1 special character</li> </ul>`) }
  const forceNewPasswordField = isForceNewPasswordRoute ? document.querySelector('div[class*="amplify-passwordfield"]') : null;
  const passwordRules = isForceNewPasswordRoute ? document.querySelector('div[class*="password-rules"]') : null;
  const forceConfirmNewPasswordField = isForceNewPasswordRoute ? document.querySelectorAll('div[class*="amplify-passwordfield"]')[1] : null;

  if (forceNewPasswordField && forceConfirmNewPasswordField && !passwordRules) {
    const passwordRules = document.createElement('div');
    passwordRules.className = 'password-rules';
    passwordRules.innerHTML = getPasswordRulesList();
    passwordRules.style.marginTop = '-20px'
    forceNewPasswordField.after(passwordRules);
    forceConfirmNewPasswordField.style.marginTop = '-20px';
  }

  return (
    <Box sx={{ mt: 20 }}>
      <Toolbar />
      <Container maxWidth='md'>
        <Stack justifyContent={"space-around"} sx={{ mt: 5 }}>
          <ThemeProvider theme={login_theme}>
            {!isSetupTOTPRoute &&
              <Authenticator hideSignUp={true} components={components} formFields={formFields} />
            }
            {isSetupTOTPRoute &&
              <Box>
                <Typography sx={{ textAlign: "center", mb: 1 }} variant="h4">
                  Set up multi-factor authentication
                </Typography>
                <Countdown seconds={900} />
                <AuthInstructions qrCode={<Authenticator hideSignUp={true} components={components} formFields={formFields} />} />
              </Box>
            }
          </ThemeProvider>
        </Stack>
      </Container>
    </Box>
  );
}

export default NewLoginComponent;

const login_theme = {
  name: 'precedent-theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          '90': '#2C537B',
          '100': '#4077b0'
        }
      }
    },
    components: {
      button: {
        fontWeight: { value: '400' },
        primary: {
          backgroundColor: { value: '{colors.brand.primary[100]}' },
          _hover: {
            backgroundColor: { value: '{colors.brand.primary[90]}' },
          },
          _focus: {
            backgroundColor: { value: '{colors.brand.primary[100]}' },
          },
          _active: {
            backgroundColor: { value: '{colors.brand.primary[100]}' },
          },
        },
      }
    },
  },
};

const formFields = {
  setupTOTP: {
    QR: {
      totpIssuer: awsExports.MFA_ISSUER
    },
    confirmation_code: {
      label: ``,
      placeholder: '6-digit passcode from Precedent-Exchange-prod',
      isRequired: true
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: 'Enter the code from your Authenticator application.',
      placeholder: 'Enter your Authenticator code:',
      isRequired: true,
    },
  }
};

const components = {
  Header() {
    return (
      <View />
    );
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        null
      );
    }
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading level={3}>
          Authenticator information
        </Heading>
      );
    }
  },
};