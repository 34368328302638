import { createSlice } from '@reduxjs/toolkit'
import { fetchTemplateDataById, fetchAvailableTemplatesForCustomer, saveTemplate } from '../thunks/adminEditTemplateData';

const formFieldData = {  //this is nested in the original state below
    templateName: {
        initialValue: '',
        value: '',
        error: '',
        isTouched: false,
        isDirty: false,
        validations: [
            {
                errorMessage: 'Required',
                validate: (value) => !!value,
            },
        ],
    },
    isCustomerDefault: {
        initialValue: false,
        value: false,
        error: '',
        isTouched: false,
        isDirty: false,
        validations: [],
    },
};


const originalAdminTemplateState = {
    precedentDefaultTemplateId: '',
    isSaving: false,
    loading: false,
    customerData: {},
    availableTemplates: [],
    availableTemplatesLoading: false,
    precedentUsers: [],
    allCustomerUsers: [],
    formMetadata: {
        isOpen: false,
        editTemplateData: {},
        allTemplateRows: [],
        isFormPristine: true,
        isFormDirty: false,
        hasAnyErrors: false,
    },
    formFieldData
}

const AdminEditTemplate = createSlice({
    name: 'AdminEditTemplate',
    initialState: originalAdminTemplateState,
    reducers: {
        setCustomerData: (state, action) => {
            state.customerData = action.payload;
        },
        setIsEditorOpen: (state, action) => {
            state.formMetadata.isOpen = action.payload;
        },
        setAvailableTemplates: (state, action) => {
            state.availableTemplates = action.payload;
        },
        setAllTemplateRows: (state, action) => { state.formMetadata.allTemplateRows = action.payload },
        setFormErrors: (state, action) => {
            action.payload.forEach(({ field, error }) => {
                state.formFieldData[field].error = error;
            });
        },
        setCodeEditorContentFetched: (state, action) => {
            state.formMetadata.codeEditorContentFetched = action.payload;
        },
        setPrecedentDefaultTemplateId: (state, action) => {
            state.precedentDefaultTemplateId = action.payload;
        },
        setFormValues: (state, action) => {
            const dirtyTracker = {};
            const errorTracker = {};
            state.formMetadata.isFormPristine = false;
            const newFormFieldData = {
                ...state.formFieldData,
                ...action.payload.reduce((acc, { field, value }) => {
                    acc[field] = {
                        ...state.formFieldData[field],
                        value,
                        isTouched: true,
                        isDirty: value !== state.formFieldData[field].initialValue,
                        error: state.formFieldData[field].validations
                            .map(({ validate, errorMessage }) => {
                                errorTracker[field] = (validate(value) ? '' : errorMessage);
                                return (validate(value) ? '' : errorMessage)

                            })
                            .find((error) => !!error),
                    };
                    dirtyTracker[field] = acc[field].isDirty;
                    errorTracker[field] = acc[field].error;
                    return acc;
                }, {}),
            };
            state.formFieldData = newFormFieldData;
            state.formMetadata.isFormDirty = Object.values(dirtyTracker).some((isDirty) => isDirty);
            state.formMetadata.hasAnyErrors = Object.values(errorTracker).some((error) => !!error);
        },
        resetFormFieldValuesOnly: (state) => {
            state.formFieldData = Object.keys(state.formFieldData).reduce((acc, key) => {
                acc[key] = {
                    ...state.formFieldData[key],
                    value: state.formFieldData[key].initialValue
                };
                return acc;

            }, {});
        },
        setPrecedentUsers: (state, action) => {
            state.precedentUsers = action.payload;
        },
        resetFormFieldsAndMetadata: (state) => {
            state.formFieldData = originalAdminTemplateState.formFieldData;
            state.formMetadata = originalAdminTemplateState.formMetadata;
        },
        resetInitiaEditTemplateState: (state) => {
            return originalAdminTemplateState;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setAllCustomerUsers: (state, action) => {
            state.allCustomerUsers = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTemplateDataById.fulfilled, (state, action) => {
            const {
                isCustomerDefault,
                templateName,
                //status
                // archived,
                // childTemplates,
                // createdTs,
                // customFieldsList,
                // customerId,
                // customerNotes,
                // live,
                // s3url,
                // systemNotes,
                // templateId,
                // userIdLastSavedBy,
                // version
            } = action.payload;

            const updatedFormData = {
                ...state.formFieldData,
                templateName: {
                    ...state.formFieldData.templateName,
                    initialValue: templateName,
                    value: templateName,
                },
                isCustomerDefault: {
                    ...state.formFieldData.isCustomerDefault,
                    initialValue: isCustomerDefault,
                    value: isCustomerDefault,
                },
            };
            state.formFieldData = updatedFormData;
            state.formMetadata.editTemplateData = {
                ...action.payload,
            };
        });

        builder.addCase(fetchAvailableTemplatesForCustomer.fulfilled, (state, action) => {
            state.availableTemplates = action?.payload || [];
            state.availableTemplatesLoading = false;
        });

        builder.addCase(fetchAvailableTemplatesForCustomer.pending, (state) => {
            state.availableTemplatesLoading = true;
        });

        builder.addCase(fetchAvailableTemplatesForCustomer.rejected, (state, payload) => {
            state.availableTemplatesLoading = false;
        });

        builder.addCase(saveTemplate.pending, (state) => {
            state.isSaving = true;
        });

        builder.addCase(saveTemplate.fulfilled, (state, action) => {
            state.isSaving = false;
        });

        builder.addCase(saveTemplate.rejected, (state) => {
            state.isSaving = false;
        });

    }
});

export const {
    setFormErrors,
    setFormValues,
    resetAllButFormFieldValues,
    resetFormFieldValuesOnly,
    resetInitiaEditTemplateState,
    resetFormFieldsAndMetadata,
    setInitialTemplateContent,
    setAllTemplateRows,
    setFormInitialValues,
    setIsEditorOpen,
    setPrecedentUsers,
    setCustomerData,
    setAvailableTemplates,
    setLoading,
    setPrecedentDefaultTemplateId,
    setAllCustomerUsers
} = AdminEditTemplate.actions;

export default AdminEditTemplate.reducer;