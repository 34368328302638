import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useSelector } from 'react-redux';
import AuthenticationLoadingSpinner from './Authentication/AuthenticationLoadingSpinner';
import { userHasRole, userHasPermission } from './common';

const RequireAuth = ({ roles = [], permissions = [], children }) => {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route, context.user]);
  const { userData } = useSelector((state) => state.User);
  const isPendingAuthorization = route === 'authenticated' && userData && Object.keys(userData).length === 0;
  const hasRole = roles?.length > 0 ? roles.some((role) => userHasRole(role, userData)) : true;
  const hasPermission = permissions?.length > 0 ? permissions.some((permission) => userHasPermission(permission, userData)) : true;
  const hasAccess = hasRole && hasPermission;
  if (route === 'idle' || isPendingAuthorization) {
    return <AuthenticationLoadingSpinner />;
  } else if (route !== 'authenticated' && route !== 'idle') {
    let target = '/login';
    return <Navigate to={target} state={{ from: location, roles: roles, permissions: permissions }} replace />;
  } else if (route === 'authenticated' && hasAccess) {
    return <>{children}</>;
  } else {
    return <Navigate to="/" replace />;
  }
};

export default RequireAuth;
