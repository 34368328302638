/**
 * Central api service definition. Specific endpoints will be defined in domain specific files and injected.
 */

import { createApi } from '@reduxjs/toolkit/query/react';
import { staggeredBaseQueryWithBailOut } from './helpers';  // Retry on status code 202
//Note: try to keep as much logic out of the api file as possible. This can be handled in custom slices or thunks.

// initialize an empty api service that we'll inject endpoints into later as needed
export const apiSlice = createApi({
  baseQuery: staggeredBaseQueryWithBailOut,
  tagTypes: ['EntityInsight', 'EntityInsightsDetail', 'DocumentInsights', 'DocumentData'],
  endpoints: () => ({}),
});
