
import React from "react";
import { formatCurrency, formatDate } from '../common';
import { Alert, AlertTitle } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import CollapseCard from "../CommonComponents/CollapseCard";
import { getMedicalsProviderAlerts } from './medicals';
import { useSelector } from "react-redux";

const MedicalsProviderSummary = ({ providerSummary }) => {
    const hasMedicalTreatments = providerSummary.length > 1;
    const { medicalsUpdateLoading } = useSelector((state) => state.Medicals);

    return (
        hasMedicalTreatments &&
        <CollapseCard title="Treatment provider summary" expanded={true}>
            {medicalsUpdateLoading ? <Skeleton variant="rectangular" height={250} /> :

                <TableContainer>
                    <Table className={"attachments-table"} sx={{
                        maxWidth: '1000px', '& .MuiTableCell-sizeMedium': {
                            paddingLeft: '16px'
                        },
                    }}>
                        <TableHead>
                            <TableRow key="header">
                                <TableCell sx={{ width: '35%', fontWeight: '500', paddingLeft: '0px !important' }}>Facility</TableCell>
                                <TableCell align='right' sx={{ fontWeight: '500' }}>Total charges</TableCell>
                                <TableCell align='right' sx={{ fontWeight: '500' }}>First treatment</TableCell>
                                <TableCell align='right' sx={{ fontWeight: '500' }}>Last treatment</TableCell>
                                <TableCell align='center' sx={{ fontWeight: '500' }}>Duration (days)</TableCell>
                                <TableCell align='center' sx={{ fontWeight: '500' }}>Visits</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {providerSummary.map((provider, index) => {
                                if (index == providerSummary.length - 1) {
                                    return (
                                        <TableRow key={provider.treatmentFacility}>
                                            <TableCell className={"secondary"} sx={{ fontWeight: '500', paddingLeft: '0px !important' }} width={"25%"}>{provider.treatmentFacility.toUpperCase()}</TableCell>
                                            <TableCell className={"secondary"} align='right' sx={{ fontWeight: '500' }}>{formatCurrency(provider.billedAmount)}</TableCell>
                                            <TableCell className={"secondary"} align='right' sx={{ fontWeight: '500' }}>{formatDate(provider.startDate)}</TableCell>
                                            <TableCell className={"secondary"} align='right' sx={{ fontWeight: '500' }}>{formatDate(provider.endDate)}</TableCell>
                                            <TableCell className={"secondary"} align='center' sx={{ fontWeight: '500' }}>{provider.treatmentDuration}</TableCell>
                                            <TableCell className={"secondary"} align='center' sx={{ fontWeight: '500' }}>{provider.uniqueDOS}</TableCell>
                                        </TableRow>
                                    )
                                }
                                return (

                                    <TableRow key={provider.treatmentFacility}>
                                        <TableCell sx={{ paddingLeft: '0px !important' }}>{provider.treatmentFacility.toUpperCase()}</TableCell>
                                        <TableCell align='right'>{formatCurrency(provider.billedAmount)}</TableCell>
                                        <TableCell align='right'>{formatDate(provider.startDate)}</TableCell>
                                        <TableCell align='right'>{formatDate(provider.endDate)}</TableCell>
                                        <TableCell align='center'>{provider.treatmentDuration}</TableCell>
                                        <TableCell align='center'>{provider.uniqueDOS}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </CollapseCard>
    )
}

export default MedicalsProviderSummary;