import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { MedicalsViewer } from './MedicalsComponents/MedicalsViewer';
import { MedicalsAdminViewer } from './MedicalsComponents/MedicalsAdminViewer';
import { MedicalsLawFirmViewer } from './MedicalsComponents/MedicalsLawFirmViewer';
import InventoryList from './InventoryList';
import { AdjusterList } from './AdjusterList';
import { AdjusterViewer } from './AdjusterViewer';
import CustomersView from './CustomerManagement/CustomersView';
import { CustomerManagementView } from './CustomerManagement/CustomerManagementView';
import { ContactManagementView } from './ContactManagement/ContactManagement';
import DemandTemplate from './DemandComponents/DemandTemplate';
import ContactLogInventoryView from './ContactLogInventoryView';
import LoginComponent from './Authentication/LoginComponent';
import RequireAuth from './RequireAuth';
import { ShortCodeResolver } from "./ShortCodeResolver";
import { Error404Page } from "./Error404Page";
import { LAW_FIRM_ROLES, PRECEDENT_ADMIN_ROLE, PRECEDENT_ROLES } from './common-roles'; 

const RouterComponent = () => {
    //Option: Add any logic/hooks here that needs to be done before rendering the routes. Has access to the store and can dispatch actions.
    return (
      <Routes> 
  
        <Route path="/login" element={<LoginComponent />} />
  
        <Route path="/inventory" element={<RequireAuth roles={[...PRECEDENT_ROLES, ...LAW_FIRM_ROLES]}><InventoryList /></RequireAuth>} />

        <Route path="/firm-inventory" element={<Navigate replace to="/inventory" />} />

        <Route path="/contact-log-inventory" element={<RequireAuth roles={PRECEDENT_ROLES}><ContactLogInventoryView /></RequireAuth>} />
  
        <Route path="/m/:documentId" element={<MedicalsViewer />} />  {/* public route for viewing medicals (carrier view) */}
  
        <Route path="/a/:documentId" element={<RequireAuth roles={PRECEDENT_ROLES}><MedicalsAdminViewer /></RequireAuth>} />  {/* admin */}  
        
        <Route path="/l/:documentId" element={<RequireAuth roles={[...LAW_FIRM_ROLES, PRECEDENT_ADMIN_ROLE]}><MedicalsLawFirmViewer /></RequireAuth>} />  {/* law firm */}
  
        <Route path="/adjuster" element={<RequireAuth><AdjusterList /></RequireAuth>} />  {/* adjuster todo: do we use this? */}
  
        <Route path="/adjuster/d/:documentId" element={<RequireAuth><AdjusterViewer /></RequireAuth>} />
    
        <Route path="/customer-management" element={<RequireAuth roles={[PRECEDENT_ADMIN_ROLE]}><CustomersView /></RequireAuth>} />
  
        <Route path="/customer-management/:customerId" element={<RequireAuth roles={[PRECEDENT_ADMIN_ROLE]}><CustomerManagementView /></RequireAuth>} />
  
        <Route path="/contact-management" element={<RequireAuth roles={[PRECEDENT_ADMIN_ROLE]}><ContactManagementView /></RequireAuth>} />
  
        <Route path="/demand-template" element={<RequireAuth roles={[PRECEDENT_ADMIN_ROLE]}><DemandTemplate /></RequireAuth>} />
  
        <Route path="/404" element={<Error404Page />} />
  
        <Route path="/:shortCode" element={<ShortCodeResolver />} />
  
        <Route path="*" element={<LoginComponent />} />   {/* catch-all route/handles redirects based on auth status */}
  
      </Routes>
    )
  }

  export default RouterComponent;