import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Alert,
  Box,
  Button,
  Tooltip,
  Badge,
} from '@mui/material';
import ConfirmationSwitch from '../CommonComponents/ConfirmationSwitch';
import { Transition } from '../Transition';
import { submitFirmApprovalRequestApi } from '../api';
import StatusDialog from './StatusDialog';
import { getMedicalTreatmentAlerts } from '../MedicalsComponents/medicals';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDocumentData } from '../redux/thunks/documentData';
import LoadingButton from '@mui/lab/LoadingButton';
import { apiSlice } from '../services/apiSlice';
import { MEDICAL_CHARGES_URL_PATH } from '../MedicalsComponents/insights';

const AttorneySendDialog = ({
  handleClose,
  dialogOpen,
  documentId,
  user,
  deliveryEmail,
  viewType,
  handler,
  source
}) => {
  const dispatch = useDispatch();
  const { documentData } = useSelector((state) => state.Document);
  const documentDataLoading = useSelector((state) => state.Document.documentDataLoading)[documentId] ?? false;
  const { medicals, medicalsLoading } = useSelector((state) => state.Medicals);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
  const [statusDialogText, setStatusDialogText] = useState('');
  const [statusDialogTitle, setStatusDialogTitle] = useState('');
  const [medicalTreatmentAlerts, setMedicalTreatmentAlerts] = useState([]);
  const [switchChecked, setSwitchChecked] = useState(false);
  const hasAlerts = medicalTreatmentAlerts.length > 0;
  const buttonIsDisabled = (hasAlerts && !switchChecked) || documentDataLoading || medicalsLoading;
  const noDemandResponseDueDate = !documentData?.demandDetails?.demandResponseDueDate || documentData?.demandDetails?.demandResponseDueDate === "Invalid date"
  const disabled = buttonIsDisabled;
  const currentDocumentFetched = documentData?.documentId === documentId;

  useEffect(() => {
    if (source === 'inventory') {
      dispatch(fetchDocumentData({ documentId, user }));
      dispatch(
        apiSlice.endpoints.getEntityInsights.initiate(
          { documentId, insightsType: MEDICAL_CHARGES_URL_PATH },
          { forceRefetch: true }
        )
      );
    }
  }, [documentId, user, source, dispatch]);

  useEffect(() => {
    if (!documentDataLoading && !medicalsLoading && currentDocumentFetched) {
      setMedicalTreatmentAlerts(getMedicalTreatmentAlerts(documentData, viewType, true, true, medicals));
    }
  }, [documentDataLoading, medicalsLoading, currentDocumentFetched, documentData, viewType, medicals]);

  const showStatusDialog = (text) => {
    setStatusDialogText(text);
    setStatusDialogOpen(true);
  };

  const handleStatusDialogClose = () => {
    setStatusDialogOpen(false);
    handleClose();
  };

  const attorneyApprove = async (userTriggered) => {
    if (userTriggered) {
      setStatusCloseDisabled(true);
      showStatusDialog('Sending demand for review...');
    }

    submitFirmApprovalRequestApi(documentId, user).then((response) => {
      if (response.status === 200) {
        setStatusDialogTitle('Approval sent');
        showStatusDialog('Success! The demand has been sent for review.');
        setStatusCloseDisabled(false);
        handler(true);
      } else if (response.status === 201 || response.status === 409) {
        setStatusDialogText('The demand is being regenerated. This should only take a few moments.');
        setTimeout(function () {
          attorneyApprove(false);
        }, 3000);
      } else {
        showStatusDialog('There was an error sending the email :-(');
        setStatusCloseDisabled(false);
        handler(false);
      }
    });
  };

  return (
    <>
      <Dialog
        maxWidth=""
        open={dialogOpen && !statusDialogOpen}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
        sx={{ overflow: 'visible' }}
      >
        <DialogContent
          sx={{
            my: 0
          }}
        >
          {hasAlerts && (
            <Box>
              <DialogContentText id="alert-dialog-slide-description">
                The following warnings exist on this demand:
              </DialogContentText>

              <div>
                {medicalTreatmentAlerts.map((alert, index) => {
                  return (
                    <Alert sx={{ mt: 1 }} key={index} severity={alert.alertSeverity}>
                      {alert.alertMessage}
                    </Alert>
                  );
                })}
              </div>
              <ConfirmationSwitch
                setSwitchChecked={setSwitchChecked}
                switchChecked={switchChecked}
                labelText="Send demand despite warnings"
              ></ConfirmationSwitch>
            </Box>
          )}

          <DialogContentText id="alert-dialog-slide-description">
            Demand will be delivered to <b>{deliveryEmail}</b>.<br />
            <br /> Are you sure you want to send?
          </DialogContentText>
        </DialogContent>
        <DialogActions >
          <Button
            onClick={() => {
              handleClose();
            }}
            color="secondary"
            variant="text"
          >
            Cancel
          </Button>

          <Tooltip title={noDemandResponseDueDate && "Add a response due date in edit demand details"} placement='top-end' sx={{ mr: noDemandResponseDueDate && 1 }}>
            <Badge badgeContent={"!"} color="warning" invisible={!noDemandResponseDueDate}>
              <span>
                <LoadingButton
                  disabled={disabled}
                  onClick={() => {
                    attorneyApprove(true);
                  }}
                  variant="contained"
                  color="secondary"
                  loading={documentDataLoading || medicalsLoading}
                >
                  Send
                </LoadingButton>
                {/* <Button
                  disabled={disabled}
                  onClick={() => {
                    attorneyApprove(true);
                  }}
                  variant="contained"
                  color="secondary"

                >
                  Send
                </Button> */}
              </span>
            </Badge>
          </Tooltip>

        </DialogActions>
      </Dialog>

      <StatusDialog
        closeDisabled={statusCloseDisabled}
        handleClose={handleStatusDialogClose}
        dialogOpen={statusDialogOpen}
        dialogText={statusDialogText}
      ></StatusDialog>
    </>
  );
};

export default AttorneySendDialog;
