import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PSPDFKit from 'pspdfkit';
import PDFSkeletonLoader from '../../../CommonComponents/PDFSkeletonLoader';
import { setToast } from '../../../redux/slices/globalToastSlice';
import { setEditDocumentPdfOpen } from '../../../redux/slices/demandDomSlice';
import {
    handleViewStateChange,
    handleDocumentChangeSave,
    toolbarItemsToRemove,
    editorItems,
    renderMUICustomCancelButton
} from './helpers';

const baseUrl = `${window.location.protocol}//${window.location.host}/assets/`;

const PdfEditor = ({ demandS3Url, user, file, setIsSaving, setLocalDocumentFiles, documentId }) => {
    const dispatch = useDispatch();
    const pdfEditorContainerRef = useRef(null);
    const document = demandS3Url;
    const { editOneDocumentFileName, fileSaveStatusMap } = useSelector(state => state.DemandDom);

    const [editorLoading, setEditorLoading] = useState(true);

    const initialViewState = new PSPDFKit.ViewState({
        zoom: PSPDFKit.ZoomMode.FIT_WIDTH,
        interactionMode: PSPDFKit.InteractionMode.DOCUMENT_EDITOR
    });

    const settings = {
        licenseKey: process.env.REACT_APP_PSPDF_LICENSE_KEY,
        baseUrl,
        document,
        initialViewState,
        toolbarItems: PSPDFKit.defaultToolbarItems.filter(item => !toolbarItemsToRemove.includes(item.type)),
        documentEditorToolbarItems: editorItems.map(item => ({ type: item })),
        styleSheets: [
            `${baseUrl}customPSPDFstyles.css`
        ],
    }

    useEffect(() => {
        pdfEditorContainerRef.current && PSPDFKit.unload(pdfEditorContainerRef.current);

        pdfEditorContainerRef.current && PSPDFKit.load({
            ...settings,
            container: pdfEditorContainerRef.current,
        })
            .then(async (instance) => {
                const totalPageNumbers = instance.totalPageCount;
                const startPage = file?.fileEntityData?.startPage || 0;
                const pageIndexArray = Array.from({ length: totalPageNumbers }, (_, index) => index);

                const pageChangeOperationsMap = pageIndexArray.map((pageIdx) => ({
                    type: 'setPageLabel',
                    pageIndexes: [pageIdx],
                    pageLabel: `${pageIdx + startPage}`
                }));
                await instance.applyOperations(pageChangeOperationsMap)
                await instance.setViewState(initialViewState);
                await instance.addEventListener("document.change", (changes) => handleDocumentChangeSave({ dispatch, changes, instance, file, setIsSaving, fileSaveStatusMap, setLocalDocumentFiles, user, editOneDocumentFileName, documentId, pdfEditorContainerRef: pdfEditorContainerRef.current, PSPDFKit }))
                await instance.addEventListener("viewState.change", (viewState, previousState) => handleViewStateChange({ dispatch, previousState, pdfEditorContainerRef: pdfEditorContainerRef.current, PSPDFKit }))

                setEditorLoading(false);

                //set custom cancel button
                const parentNode = window.document.createElement('button');
                renderMUICustomCancelButton({ parentNode, dispatch, pdfEditorContainerRef});
            
                const customCancelButton = {
                    type: "custom", // Specify the type as custom
                    id: "CustomCancel", // Unique identifier for the button
                    node: parentNode, // The button element
                    onPress: (event) => {
                        dispatch(setEditDocumentPdfOpen(false));
                        pdfEditorContainerRef.current && PSPDFKit.unload(pdfEditorContainerRef.current);
                    }
                };

                instance.setDocumentEditorFooterItems((items) => {
                    const spacer = items.find((item) => item.type === "spacer");
                    const filteredItems = items.filter((item) => item.type === "save");
                    return [
                        customCancelButton,
                        spacer,
                        ...filteredItems
                    ];
                });
            })
            .catch(error => {
                setEditorLoading(false);
                dispatch(setToast({ message: error.toString(), severity: 'error', isOpen: true }));
            });
    }, [PSPDFKit, document]);


    return (
        <>
            <div
                style={{
                    height: '100vh',
                    display: editorLoading ? 'block' : 'none'
                }}>
                <PDFSkeletonLoader height={'100vh'} />
            </div>

            <div
                ref={pdfEditorContainerRef}
                style={{
                    width: '100%',
                    height: '100%',
                    marginTop: '60px',
                    display: editorLoading ? 'none' : 'block'
                }}
            />

        </>
    );
};

export default PdfEditor;
